import { useMutation, useQuery } from '@apollo/client';
import {
  Box,
  Dialog,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { GET_IBC, UPDATE_IBC, UPDATE_REVIEWER } from 'apollo-client';
import { ChangeEventHandler, FC, MouseEventHandler, useState } from 'react';
import { IGetIbcData, IReviewer } from 'types';
import { ContributionLogForm } from 'components/forms/ContributionLogForm';
import { useAuth } from 'context/authProvider';
import { GoalForm } from 'components/forms/GoalForm';
import { GoalsTableComponent } from './GoalsTable';
import { ContributionLogsTableComponent } from './ContributionLogTable';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { ErrorSnackbar } from './ErrorSnackbar';
import { InfoSnackbar } from './InfoSnackbar';
import {
  ActionButton,
  CustomSelect,
  DocumentContentWrapper,
  IconButtonStyle,
  MyActionButton,
  SalaryTypography,
} from 'styles/stylesNew';
import { DateTime } from 'luxon';
import { SuccessSnackbar } from './SuccessSnackbar';

interface Props {
  ibcId: number;
  userId: number;
  reviewers?: IReviewer[];
  deleteIbc?: (id: number) => Promise<void>;
  reviewer?: IReviewer;
}

export const Document: FC<Props> = ({
  ibcId,
  userId,
  reviewers,
  deleteIbc,
  reviewer,
}) => {
  const { loading, error, data, refetch } = useQuery<IGetIbcData>(GET_IBC, {
    variables: {
      getIbcFilter: {
        id: ibcId,
      },
    },
  });
  const { currentUser } = useAuth();
  const [salary, setSalary] = useState<number>(0);
  const [selectReviewer, setSelectReviewer] = useState<IReviewer | undefined>(
    reviewer ? reviewer : reviewers ? reviewers[0] : undefined
  );
  const [updateSalary, setUpdateSalary] = useState<boolean>(false);
  const [openContributionLog, setOpenContributionLog] =
    useState<boolean>(false);
  const [openPerformanceGoal, setOpenPerformanceGoal] =
    useState<boolean>(false);
  const [openDevelopmentGoal, setOpenDevelopmentGoal] =
    useState<boolean>(false);

  const [
    reviewerMutation,
    { error: updateReviewerError, data: updateReviewerData },
  ] = useMutation(UPDATE_REVIEWER);

  const [updateMutation, { error: updateIbcError, data: updateIbcData }] =
    useMutation(UPDATE_IBC);

  const updateReviewer: MouseEventHandler<HTMLButtonElement> = async () => {
    await reviewerMutation({
      variables: {
        updateIbcInput: {
          id: ibcId,
          reviewer: selectReviewer?.id,
        },
      },
    });
    refetch();
  };

  const handleSalaryChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setSalary(Number(e.target.value));
  };

  const changeReviewer: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => void = (e) => {
    const selectedReviewer = reviewers?.find(
      (reviewer) => reviewer.id === e.target.value
    );
    setSelectReviewer(selectedReviewer);
  };

  const updateSalaryClick = async () => {
    await updateMutation({
      variables: {
        updateIbcInput: {
          id: ibcId,
          annualSalary: salary,
        },
      },
    });
    refetch();
    setSalary(0);
    setUpdateSalary(false);
  };

  const handleStatusChange = async (
    ibcId: number,
    status?: number,
    e?: any
  ) => {
    let s = status;
    if (!status) {
      s = e?.target.value;
    }
    await updateMutation({
      variables: {
        updateIbcInput: {
          id: ibcId,
          status: s,
        },
      },
    });
    refetch();
  };
  if (loading) {
    return <InfoSnackbar message={'Loading...'} open={true} />;
  }

  const renderAddGoalButtons =
    (Number(data?.getIbc.status.id) === 1 && currentUser?.id === userId) ||
    currentUser?.isAdmin;

  const renderAddContributionLogButton =
    (currentUser?.id === userId || currentUser?.isAdmin) &&
    Number(data?.getIbc.status.id) !== 4;

  const renderReviewerButton =
    Number(data?.getIbc.reviewer?.id) === currentUser?.id &&
    !currentUser?.isAdmin;

  const renderDeleteIbcButton =
    currentUser?.isAdmin || Number(data?.getIbc.status.id) === 1;

  const renderUpdateSalary =
    currentUser?.isAdmin ||
    (currentUser?.id === userId && Number(data?.getIbc.status.id) === 1);

  return data ? (
    <Grid container direction='column'>
      <Grid container justifyContent='space-between' alignItems='center'>
        <Typography variant='h6'>Status: {data.getIbc.status.name}</Typography>
        {deleteIbc && renderDeleteIbcButton && (
          <IconButtonStyle onClick={() => deleteIbc(ibcId)} edge='end'>
            <DeleteOutlineOutlinedIcon fontSize='large' />
          </IconButtonStyle>
        )}
      </Grid>
      {currentUser?.isAdmin && (
        <Grid container alignItems='center'>
          <Typography>Change document status: </Typography>
          <CustomSelect
            value={data.getIbc.status.id}
            onChange={(e) =>
              handleStatusChange(data.getIbc.id as number, undefined, e)
            }
          >
            <MenuItem value={1}>Not submitted</MenuItem>
            <MenuItem value={2}>Submited</MenuItem>
            <MenuItem value={3}>Mid Year Checkpoint Completed</MenuItem>
            <MenuItem value={4}>End of Year Checkpoint Completed</MenuItem>
          </CustomSelect>
        </Grid>
      )}
      {reviewer && (
        <Typography variant='h6'>Reviewer: {reviewer.name}</Typography>
      )}
      {reviewers && (
        <Grid container alignItems='center'>
          <MyActionButton onClick={updateReviewer}>Set reviewer</MyActionButton>
          <CustomSelect
            value={selectReviewer?.id}
            onChange={changeReviewer}
            id='reviewer-select'
          >
            {reviewers.map((reviewer) => (
              <MenuItem key={reviewer.id} value={reviewer.id}>
                {reviewer.name}
              </MenuItem>
            ))}
          </CustomSelect>
        </Grid>
      )}
      {data.getIbc.midReviewDate && Number(data.getIbc.status.id) >= 3 && (
        <Typography>
          Mid year review date:{' '}
          {DateTime.fromISO(data.getIbc.midReviewDate).toLocaleString()}
        </Typography>
      )}
      {data.getIbc.eoyReviewDate && Number(data.getIbc.status.id) === 4 && (
        <Typography>
          End of year review date:{' '}
          {DateTime.fromISO(data.getIbc.eoyReviewDate).toLocaleString()}
        </Typography>
      )}
      {data.getIbc.annualSalary &&
        (updateSalary ? (
          <Grid container alignItems='center'>
            <TextField type='number' onChange={handleSalaryChange} />
            <ActionButton onClick={updateSalaryClick}>OK</ActionButton>
            <ActionButton
              onClick={() => {
                setUpdateSalary(false);
                setSalary(0);
              }}
            >
              Cancel
            </ActionButton>
          </Grid>
        ) : (
          <Grid container alignItems='center'>
            {renderUpdateSalary && (
              <MyActionButton
                onClick={() => setUpdateSalary((prevState) => !prevState)}
              >
                Update salary
              </MyActionButton>
            )}
            {renderUpdateSalary ? (
              <SalaryTypography>
                Salary: {data.getIbc.annualSalary} $
              </SalaryTypography>
            ) : (
              <Typography>Salary: {data.getIbc.annualSalary} $</Typography>
            )}
          </Grid>
        ))}

      {renderReviewerButton && (
        <Box>
          {Number(data?.getIbc.status.id) === 1 && (
            <MyActionButton
              onClick={() => handleStatusChange(data?.getIbc.id as number, 2)}
            >
              Submit
            </MyActionButton>
          )}
          {Number(data?.getIbc.status.id) === 2 && (
            <MyActionButton
              onClick={() => handleStatusChange(data?.getIbc.id as number, 3)}
            >
              Mid Year Checkpoint Completed
            </MyActionButton>
          )}
          {Number(data?.getIbc.status.id) === 3 && (
            <MyActionButton
              onClick={() => handleStatusChange(data?.getIbc.id as number, 4)}
            >
              End of Year Checkpoint Completed
            </MyActionButton>
          )}
        </Box>
      )}
      <DocumentContentWrapper>
        <Typography variant='h5'>Performance goals</Typography>
        {renderAddGoalButtons && (
          <MyActionButton
            onClick={() => setOpenPerformanceGoal((prevState) => !prevState)}
          >
            Add performance goal
          </MyActionButton>
        )}
        <Dialog open={openPerformanceGoal}>
          <GoalForm
            name='Performance goal'
            ibcId={ibcId}
            setOpenPerformanceGoal={setOpenPerformanceGoal}
            refetch={refetch}
          />
        </Dialog>
        {data.getIbc.performanceGoals.length >= 1 ? (
          <GoalsTableComponent
            refetch={refetch}
            name='Performance goal'
            goals={data.getIbc.performanceGoals}
            status={Number(data.getIbc.status.id)}
            userId={userId}
            ibcId={ibcId}
          />
        ) : (
          <Typography>No performance goals.</Typography>
        )}
      </DocumentContentWrapper>
      <DocumentContentWrapper>
        <Typography variant='h5'>Development goals</Typography>
        {renderAddGoalButtons && (
          <MyActionButton
            onClick={() => setOpenDevelopmentGoal((prevState) => !prevState)}
          >
            Add development goal
          </MyActionButton>
        )}
        <Dialog open={openDevelopmentGoal}>
          <GoalForm
            name='Development goal'
            ibcId={ibcId}
            setOpenDevelopmentGoal={setOpenDevelopmentGoal}
            refetch={refetch}
          />
        </Dialog>
        {data.getIbc.developmentGoals.length >= 1 ? (
          <GoalsTableComponent
            refetch={refetch}
            name='Development goal'
            goals={data.getIbc.developmentGoals}
            status={Number(data.getIbc.status.id)}
            userId={userId}
            ibcId={ibcId}
          />
        ) : (
          <Typography>No development goals.</Typography>
        )}
      </DocumentContentWrapper>
      <DocumentContentWrapper>
        <Typography variant='h5'>Contribution logs</Typography>
        {renderAddContributionLogButton && (
          <MyActionButton
            onClick={() => setOpenContributionLog((prevState) => !prevState)}
          >
            Add contribution log
          </MyActionButton>
        )}
        {data.getIbc.contributionLogs.length >= 1 ? (
          <ContributionLogsTableComponent
            refetch={refetch}
            contributionLogs={data.getIbc.contributionLogs}
            status={Number(data.getIbc.status.id)}
            userId={userId}
          />
        ) : (
          <Typography>No contribution logs.</Typography>
        )}
        <Dialog open={openContributionLog}>
          <ContributionLogForm
            refetch={refetch}
            ibcId={data.getIbc.id}
            setOpenContributionLog={setOpenContributionLog}
          />
        </Dialog>
      </DocumentContentWrapper>
      {error && <ErrorSnackbar message={error.message} open={true} />}
      {updateIbcError && (
        <ErrorSnackbar message={updateIbcError.message} open={true} />
      )}
      {updateReviewerError && (
        <ErrorSnackbar message={updateReviewerError.message} open={true} />
      )}
      {updateReviewerData && (
        <SuccessSnackbar
          message={'Reviewer successfully updated!'}
          open={true}
        />
      )}
      {updateIbcData && (
        <SuccessSnackbar message={'Successfully updated IBC!'} open={true} />
      )}
    </Grid>
  ) : null;
};
