import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAuth } from '../../context/authProvider';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  ButtonAuth,
  LoginForm,
  LoginFormMain,
  MyField,
  RememberMeCheckbox,
} from 'styles/stylesNew';
import { Grid, Typography } from '@material-ui/core';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password should be of minimum 8 characters length')
    .required('Password is required'),
});

export const Login = () => {
  const { login } = useAuth();
  const history = useHistory();
  const [checked, setChecked] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: {
      email: 'example@iolap.com',
      password: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        await login(values.email, values.password, checked);
      } catch (err) {
        console.error(err);
      }
    },
  });

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token !== null) {
      history.push('/my-ibcs');
    }
  }, [history]);

  const handleCheckChange = () => {
    setChecked((prevState) => !prevState);
  };

  return (
    <LoginFormMain>
      <img src='logo-iolap.png' alt='Login iOLAP' />
      <Typography variant='h4'>Individual Business Commitments</Typography>
      <LoginForm>
        <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
          <MyField
            id='email'
            name='email'
            label='Email'
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            fullWidth
          />
          <MyField
            id='password'
            name='password'
            label='Password'
            type='password'
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            fullWidth
          />
          <Grid container alignItems='center'>
            <RememberMeCheckbox
              checked={checked}
              onChange={handleCheckChange}
              color='primary'
            />
            <Typography>Remember me</Typography>
          </Grid>
          <ButtonAuth type='submit'>Login</ButtonAuth>
        </form>
      </LoginForm>
      <footer style={{ position: 'absolute', bottom: 0 }}>
        All rights reserved by iOLAP&reg; 2021
      </footer>
    </LoginFormMain>
  );
};
