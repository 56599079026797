import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import FolderIcon from '@material-ui/icons/Folder';

export const AdminListItems: FC = () => {
  return (
    <div>
      <Divider />
      <List>
        <ListItem button component={Link} to={'/view-users'}>
          <ListItemIcon>
            <PeopleAltIcon />
          </ListItemIcon>
          <ListItemText primary={'Users'} />
        </ListItem>
        <ListItem button component={Link} to={'/admin-ibcs'}>
          <ListItemIcon>
            <FolderIcon />
          </ListItemIcon>
          <ListItemText primary={"IBC's"} />
        </ListItem>
      </List>
    </div>
  );
};
