import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
  Typography,
  Dialog,
  Box,
} from '@material-ui/core';
import { FC, useState } from 'react';
import { IContributionLog, IGetIbcData } from 'types';
import { DateTime } from 'luxon';
import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { UpdateContributionLogForm } from 'components/forms/UpdateContributionLogForm';
import { DELETE_CONTRIBUTION_LOG } from 'apollo-client';
import { useAuth } from 'context/authProvider';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import { IconButtonStyle } from 'styles/stylesNew';

interface Props {
  contributionLogs: IContributionLog[];
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<IGetIbcData>>;
  status: number;
  userId: number;
}

export const ContributionLogsTableComponent: FC<Props> = ({
  contributionLogs,
  refetch,
  status,
  userId,
}) => {
  const { currentUser } = useAuth();
  const [contributionLog, setContributionLog] = useState<IContributionLog>();
  const [updateContributionLog, setUpdateContributionLog] =
    useState<boolean>(false);

  const [deleteMutation] = useMutation(DELETE_CONTRIBUTION_LOG);

  const updateLog = (log: IContributionLog) => {
    setContributionLog(log);
    setUpdateContributionLog((prevState) => !prevState);
  };

  const deleteLog = async (log: IContributionLog) => {
    await deleteMutation({
      variables: {
        deleteContributionLogInput: {
          id: log.id,
        },
      },
    });
    refetch();
  };

  const renderEditButton =
    (currentUser?.isAdmin || currentUser?.id === userId) && status !== 4;
  const renderDeleteButton = currentUser?.isAdmin;

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow style={{ background: 'rgba(247, 143, 30, 0.8)' }}>
              <TableCell>
                <Typography variant='subtitle1'>
                  <strong>Accomplishment</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant='subtitle1'>
                  <strong>Business impact</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant='subtitle1'>
                  <strong>Date</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant='subtitle1'>
                  <strong>Actions</strong>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {contributionLogs.map((log) => (
              <TableRow key={log.id}>
                <TableCell component='th' scope='row'>
                  {log.accomplishment}
                </TableCell>
                <TableCell>{log.businessImpact}</TableCell>
                <TableCell>
                  {DateTime.fromISO(log.date).toLocaleString()}
                </TableCell>
                <TableCell>
                  {renderEditButton && (
                    <IconButtonStyle onClick={() => updateLog(log)}>
                      <EditOutlinedIcon />
                    </IconButtonStyle>
                  )}
                  {renderDeleteButton && (
                    <IconButtonStyle onClick={() => deleteLog(log)}>
                      <DeleteOutlineOutlinedIcon />
                    </IconButtonStyle>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Container>
        <Dialog open={updateContributionLog}>
          <UpdateContributionLogForm
            log={contributionLog}
            refetch={refetch}
            setUpdateContributionLog={setUpdateContributionLog}
          />
        </Dialog>
      </Container>
    </Box>
  );
};
