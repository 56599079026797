import { ChangeEventHandler, FC } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_USERS, UPDATE_USER } from 'apollo-client';
import { useEffect } from 'react';
import { useState } from 'react';
import { UserTable } from './UserTable';
import { Container, Typography } from '@material-ui/core';
import { IUser } from 'types';
import { MouseEventHandler } from 'react';
import { AddUserForm } from 'components/forms/AddUserForm';
import { UpdateUserForm } from 'components/forms/UpdateUserForm';
import PersonAddOutlinedIcon from '@material-ui/icons/PersonAddOutlined';
import { ErrorSnackbar } from '../common/ErrorSnackbar';
import { SuccessSnackbar } from '../common/SuccessSnackbar';
import { InfoSnackbar } from '../common/InfoSnackbar';
import {
  IconButtonStyle,
  MyActionButton,
  MyField,
  PageOptions,
} from 'styles/stylesNew';

interface IData {
  getUsers: IUser[];
}

export const ViewUsers: FC = () => {
  const { loading, error, data, refetch } = useQuery<IData>(GET_USERS);
  const [showInactive, setShowInactive] = useState<boolean>(false);
  const [updateMutation, { error: deleteError, data: deleteData }] =
    useMutation(UPDATE_USER);

  const [filterUsers, setFilterUsers] = useState<string>('');
  const [user, setUser] = useState<IUser>();
  const [users, setUsers] = useState<IUser[]>([]);
  const [openAddUserForm, setOpenAddUserForm] = useState<boolean>(false);

  useEffect(() => {
    if (data) {
      setUsers(data.getUsers);
    }
  }, [data]);

  const handleFilterChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFilterUsers(e.target.value);
  };

  const onAddUserClick: MouseEventHandler<HTMLButtonElement> = () => {
    setOpenAddUserForm((prevState) => !prevState);
  };

  const onDeleteUserClick = async (id: number) => {
    await updateMutation({
      variables: {
        updateUserInput: {
          id,
          activeUser: false,
        },
      },
    });

    refetch();
  };

  const onUpdateUserClick = (user: IUser) => {
    setUser(user);
  };

  if (loading) {
    return <InfoSnackbar message={'Loading...'} open={true} />;
  }

  return users ? (
    <Container maxWidth={false}>
      <Typography variant='h4'>View users</Typography>
      <PageOptions
        container
        justifyContent='space-between'
        alignItems='flex-end'
      >
        <IconButtonStyle onClick={onAddUserClick}>
          <PersonAddOutlinedIcon fontSize='large' />
        </IconButtonStyle>
        <MyField
          id='filterUsers'
          name='filterUsers'
          onChange={handleFilterChange}
          value={filterUsers}
          label='Filter users'
        />
        <MyActionButton
          onClick={() => setShowInactive((prevState) => !prevState)}
        >
          Show inactive users
        </MyActionButton>
      </PageOptions>
      <UserTable
        rows={users.filter((user) =>
          user.name.toLowerCase().includes(filterUsers.toLowerCase())
        )}
        onDeleteUserClick={onDeleteUserClick}
        onUpdateUserClick={onUpdateUserClick}
        showInactive={showInactive}
      />
      {openAddUserForm && (
        <AddUserForm
          refetch={refetch}
          setOpenAddUserForm={setOpenAddUserForm}
        />
      )}
      {user && (
        <UpdateUserForm refetch={refetch} user={user} setUser={setUser} />
      )}
      {error && <ErrorSnackbar message={error.message} open={true} />}
      {deleteError && (
        <ErrorSnackbar message={deleteError.message} open={true} />
      )}
      {deleteData && (
        <SuccessSnackbar
          message={'User status changed to inactive!'}
          open={true}
        />
      )}
    </Container>
  ) : null;
};
