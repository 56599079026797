import { Grid, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { FC } from 'react';
import * as yup from 'yup';
import { useState } from 'react';
import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import {
  UPDATE_DEVELOPMENT_GOAL,
  UPDATE_PERFORMANCE_GOAL,
} from 'apollo-client';
import { IGetIbcData, IGoals } from 'types';
import { useAuth } from 'context/authProvider';
import { ErrorSnackbar } from 'components/views/common/ErrorSnackbar';
import { SuccessSnackbar } from 'components/views/common/SuccessSnackbar';
import { FormButtons, FormContainer, MyField } from 'styles/stylesNew';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import LuxonUtils from '@date-io/luxon';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

const validationSchema = yup.object({
  goal: yup.string(),
  supportNeeded: yup.string(),
  midYearStatus: yup.string(),
  eoyYearStatus: yup.string(),
});

interface Props {
  name: string;
  goalToUpdate: IGoals;
  setUpdateGoal: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<IGetIbcData>>;
  status: number;
}

export const UpdateGoalForm: FC<Props> = ({
  goalToUpdate,
  name,
  setUpdateGoal,
  refetch,
  status,
}) => {
  const { currentUser } = useAuth();
  const [updateMutation, { error, data }] = useMutation(
    name === 'Performance goal'
      ? UPDATE_PERFORMANCE_GOAL
      : UPDATE_DEVELOPMENT_GOAL
  );
  const [dateError, setDateError] = useState<string>('');
  const [startDate, setStartDate] = useState<Date | undefined>(
    new Date(goalToUpdate?.startDate)
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    new Date(goalToUpdate?.endDate)
  );
  const formik = useFormik({
    initialValues: {
      goal: goalToUpdate?.goalDescription,
      supportNeeded: goalToUpdate?.supportNeeded,
      midYearStatus:
        goalToUpdate?.midYearStatus === null ? '' : goalToUpdate?.midYearStatus,
      eoyYearStatus:
        goalToUpdate?.eoyYearStatus === null ? '' : goalToUpdate?.eoyYearStatus,
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      if (startDate! > endDate!) {
        return setDateError('End date cannot be lower than start date!');
      }
      const goal =
        name === 'Performance goal'
          ? 'updatePerformanceGoalInput'
          : 'updateDevelopmentGoalInput';
      await updateMutation({
        variables: {
          [goal]: {
            id: goalToUpdate?.id,
            goalDescription: values.goal,
            supportNeeded: values.supportNeeded,
            midYearStatus: values.midYearStatus,
            eoyYearStatus: values.eoyYearStatus,
            startDate,
            endDate,
          },
        },
      });

      resetForm({});
      setStartDate(new Date());
      setEndDate(new Date());
      setUpdateGoal(false);
      refetch();
    },
  });

  const cancelSubmit = () => {
    setUpdateGoal(false);
  };

  const handleStartDateChange = (date: MaterialUiPickersDate) => {
    const jsDate = date?.toJSDate();
    setStartDate(jsDate);
  };

  const handleEndDateChange = (date: MaterialUiPickersDate) => {
    const jsDate = date?.toJSDate();
    setEndDate(jsDate);
  };

  const renderMidYearStatusUpdate =
    currentUser?.isAdmin || (currentUser?.isReviewer && status === 2);
  const renderEoyStatusUpdate =
    currentUser?.isAdmin || (currentUser?.isReviewer && status === 3);

  return (
    <FormContainer>
      <form onSubmit={formik.handleSubmit}>
        <Typography variant='h5'>{name}</Typography>
        <MyField
          name='goal'
          id='goal'
          label='Goal'
          value={formik.values.goal}
          onChange={formik.handleChange}
          error={formik.touched.goal && Boolean(formik.errors.goal)}
          helperText={formik.touched.goal && formik.errors.goal}
          fullWidth
        />
        <MyField
          name='supportNeeded'
          id='supportNeeded'
          label='Resources/Support needed'
          value={formik.values.supportNeeded}
          onChange={formik.handleChange}
          error={
            formik.touched.supportNeeded && Boolean(formik.errors.supportNeeded)
          }
          helperText={
            formik.touched.supportNeeded && formik.errors.supportNeeded
          }
          fullWidth
        />
        {renderMidYearStatusUpdate && (
          <MyField
            name='midYearStatus'
            id='midYearStatus'
            label='Mid year status'
            value={formik.values.midYearStatus}
            onChange={formik.handleChange}
            error={
              formik.touched.midYearStatus &&
              Boolean(formik.errors.midYearStatus)
            }
            helperText={
              formik.touched.midYearStatus && formik.errors.midYearStatus
            }
            fullWidth
          />
        )}
        {renderEoyStatusUpdate && (
          <MyField
            name='eoyYearStatus'
            id='eoyYearStatus'
            label='End of year status'
            value={formik.values.eoyYearStatus}
            onChange={formik.handleChange}
            error={
              formik.touched.eoyYearStatus &&
              Boolean(formik.errors.eoyYearStatus)
            }
            helperText={
              formik.touched.eoyYearStatus && formik.errors.eoyYearStatus
            }
            fullWidth
          />
        )}
        <Grid container justifyContent='space-around'>
          <MuiPickersUtilsProvider utils={LuxonUtils}>
            <KeyboardDatePicker
              disablePast
              format='MM/dd/yy'
              margin='normal'
              initialFocusedDate={new Date()}
              onChange={handleStartDateChange}
              label='Start date'
              value={startDate}
              variant='inline'
              disableToolbar
              autoOk
            />
            <KeyboardDatePicker
              disablePast
              format='MM/dd/yy'
              margin='normal'
              initialFocusedDate={new Date()}
              onChange={handleEndDateChange}
              label='End date'
              value={endDate}
              variant='inline'
              disableToolbar
              autoOk
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid container justifyContent='space-between'>
          <FormButtons type='submit'>Submit</FormButtons>
          <FormButtons onClick={cancelSubmit}>Cancel</FormButtons>
        </Grid>
      </form>
      {error && <ErrorSnackbar message={error.message} open={true} />}
      {data && (
        <SuccessSnackbar
          message={`Successfully updated ${name}!`}
          open={true}
        />
      )}
      {dateError && <ErrorSnackbar message={dateError} open={true} />}
    </FormContainer>
  );
};
