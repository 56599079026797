import { Grid, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import { FC } from 'react';
import * as yup from 'yup';
import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import { UPDATE_CONTRIBUTION_LOG } from 'apollo-client';
import { IContributionLog, IGetIbcData } from 'types';
import { ErrorSnackbar } from 'components/views/common/ErrorSnackbar';
import { FormButtons, FormContainer, MyField } from 'styles/stylesNew';
import { SuccessSnackbar } from 'components/views/common/SuccessSnackbar';

const validationSchema = yup.object({
  accomplishment: yup.string(),
  supportNeeded: yup.string(),
});

interface Props {
  log: IContributionLog | undefined;
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<IGetIbcData>>;
  setUpdateContributionLog: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UpdateContributionLogForm: FC<Props> = ({
  log,
  refetch,
  setUpdateContributionLog,
}) => {
  const [updateMutation, { error, data }] = useMutation(
    UPDATE_CONTRIBUTION_LOG
  );
  const formik = useFormik({
    initialValues: {
      accomplishment: log?.accomplishment,
      businessImpact: log?.businessImpact,
    },
    validationSchema,

    onSubmit: async (values, { resetForm }) => {
      await updateMutation({
        variables: {
          updateContributionLogInput: {
            id: log?.id,
            accomplishment: values.accomplishment,
            businessImpact: values.businessImpact,
          },
        },
      });
      resetForm({});
      setUpdateContributionLog(false);
      refetch();
    },
  });

  const cancelSubmit = () => {
    setUpdateContributionLog(false);
  };

  return (
    <FormContainer>
      <form onSubmit={formik.handleSubmit}>
        <Typography variant='h5'>Contribution Log</Typography>
        <MyField
          name='accomplishment'
          id='accomplishment'
          label='Accomplishment'
          value={formik.values.accomplishment}
          onChange={formik.handleChange}
          error={
            formik.touched.accomplishment &&
            Boolean(formik.errors.accomplishment)
          }
          helperText={
            formik.touched.accomplishment && formik.errors.accomplishment
          }
          fullWidth
        />
        <MyField
          name='businessImpact'
          id='businessImpact'
          label='Business impact'
          value={formik.values.businessImpact}
          onChange={formik.handleChange}
          error={
            formik.touched.businessImpact &&
            Boolean(formik.errors.businessImpact)
          }
          helperText={
            formik.touched.businessImpact && formik.errors.businessImpact
          }
          fullWidth
        />
        <Grid container justifyContent='space-between'>
          <FormButtons type='submit'>Submit</FormButtons>
          <FormButtons onClick={cancelSubmit}>Cancel</FormButtons>
        </Grid>
      </form>
      {error && <ErrorSnackbar message={error.message} open={true} />}
      {data && (
        <SuccessSnackbar
          message={'Successfully updated contribution log!'}
          open={true}
        />
      )}
    </FormContainer>
  );
};
