import { useMutation, useQuery } from '@apollo/client';
import {
  Accordion,
  AccordionDetails,
  Container,
  FormControl,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { DELETE_IBC, GET_ALL_IBCS, GET_REVIEWERS } from 'apollo-client';
import {
  ChangeEventHandler,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react';
import { FC } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Document } from 'components/views/common/Document';
import { IUser, TSortOrder, IIbc, IReviewer } from 'types';
import { ErrorSnackbar } from '../common/ErrorSnackbar';
import { InfoSnackbar } from '../common/InfoSnackbar';
import {
  CustomAccordionSummary,
  DocumentsGridWrapper,
  MyField,
  PageOptions,
  SortButton,
  StatusSelect,
  UserContainer,
} from 'styles/stylesNew';
import { SuccessSnackbar } from '../common/SuccessSnackbar';

type TUser = Pick<IUser, 'id' | 'name'>;
interface IGetIbcsData {
  getIbcs: IIbc[];
}

interface IGetReviewersData {
  getReviewers: IReviewer[];
}

export const ViewAllIbcs: FC = () => {
  const [filterUsers, setFilterUsers] = useState<string>('');
  const [users, setUsers] = useState<TUser[]>([]);
  const [ibcs, setIbcs] = useState<IIbc[]>([]);
  const [reviewers, setReviewers] = useState<IReviewer[]>();
  const [sortOrder, setSortOrder] = useState<TSortOrder>('asc');
  const [filterStatus, setFilterStatus] = useState<number>(1);
  const {
    loading: getIbcsLoading,
    error: getIbcsError,
    data: getIbcsData,
    refetch,
  } = useQuery<IGetIbcsData>(GET_ALL_IBCS);
  const { error: getReviewersError, data: getReviewersData } =
    useQuery<IGetReviewersData>(GET_REVIEWERS);

  const [deleteIbcMutation, { error: deleteIbcError, data: deleteIbcData }] =
    useMutation(DELETE_IBC);

  useEffect(() => {
    if (getIbcsData) {
      const userArray = getIbcsData.getIbcs.map((ibc) => ibc.user);
      const uniqueUsers = [...new Set([...userArray])];
      setUsers(uniqueUsers);
      const sortIbcs = [...getIbcsData.getIbcs].sort(
        (a, b) => a.ibcYear - b.ibcYear
      );
      setIbcs(sortIbcs);
    }
    refetch();
  }, [getIbcsData, refetch]);

  useEffect(() => {
    if (getReviewersData) {
      setReviewers(getReviewersData.getReviewers);
    }
  }, [getReviewersData]);

  const deleteIbc = async (id: number) => {
    await deleteIbcMutation({
      variables: {
        deleteIbcInput: {
          id,
        },
      },
      update(cache) {
        const normalizedId = cache.identify({ id, __typename: 'Ibc' });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
  };

  const handleFilterChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFilterUsers(e.target.value);
  };

  const handleClick: MouseEventHandler<HTMLButtonElement> = () => {
    setSortOrder((prevState) => (prevState === 'asc' ? 'desc' : 'asc'));
    const sortedIbcs = [...ibcs].sort((a, b) =>
      sortOrder === 'asc' ? b.ibcYear - a.ibcYear : a.ibcYear - b.ibcYear
    );
    setIbcs(sortedIbcs);
  };

  const handleStatusFilter: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => void = (e) => {
    setFilterStatus(Number(e.target.value));
  };

  if (getIbcsLoading) {
    return <InfoSnackbar message={'Loading...'} open={true} />;
  }

  return (
    <Container maxWidth={false}>
      <Typography variant='h4'>All IBC's</Typography>
      <PageOptions
        container
        alignItems='flex-end'
        justifyContent='space-between'
      >
        <FormControl>
          <StatusSelect
            value={filterStatus}
            onChange={handleStatusFilter}
            id='reviewer-select'
          >
            <MenuItem value={1}>Not submitted</MenuItem>
            <MenuItem value={2}>Submitted</MenuItem>
            <MenuItem value={3}>Mid year checkpoint completed</MenuItem>
            <MenuItem value={4}>End of year checkpoint completed</MenuItem>
          </StatusSelect>
        </FormControl>
        <MyField
          id='filterUsers'
          name='filterUsers'
          onChange={handleFilterChange}
          value={filterUsers}
          label='Filter users'
        />
        <SortButton onClick={handleClick}>Sort by year: {sortOrder}</SortButton>
      </PageOptions>
      {getIbcsData?.getIbcs && (
        <DocumentsGridWrapper container direction='column'>
          {users
            ?.filter((user) =>
              user.name.toLowerCase().includes(filterUsers.toLowerCase())
            )
            .map((user) => {
              if (
                ibcs.filter(
                  (ibc) =>
                    ibc.user.id === user.id &&
                    Number(ibc.status.id) === filterStatus
                ).length < 1
              ) {
                return null;
              }
              return (
                <UserContainer container direction='column' key={user.id}>
                  <Typography variant='h5'>{user.name}</Typography>
                  {ibcs
                    .filter(
                      (ibc) =>
                        ibc.user.id === user.id &&
                        Number(ibc.status.id) === filterStatus
                    )
                    .map((ibc) => {
                      return (
                        <Accordion key={ibc.id}>
                          <CustomAccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='ibc'
                            id='ibc'
                          >
                            <Typography variant='h6'>{ibc.ibcYear}</Typography>
                            {!ibc.reviewer && <Typography>*</Typography>}
                          </CustomAccordionSummary>
                          <AccordionDetails
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <Document
                              ibcId={ibc.id}
                              userId={ibc.user.id}
                              reviewers={reviewers}
                              deleteIbc={deleteIbc}
                              reviewer={ibc.reviewer}
                            />
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                </UserContainer>
              );
            })}
        </DocumentsGridWrapper>
      )}
      {getIbcsError && (
        <ErrorSnackbar message={getIbcsError.message} open={true} />
      )}
      {getReviewersError && (
        <ErrorSnackbar message={getReviewersError.message} open={true} />
      )}
      {deleteIbcData && (
        <SuccessSnackbar
          message={'Document successfully deleted!'}
          open={true}
        />
      )}
      {deleteIbcError && (
        <ErrorSnackbar message={deleteIbcError.message} open={true} />
      )}
    </Container>
  );
};
