import { Typography } from '@material-ui/core';
import { FC } from 'react';
import { MainContainer } from 'styles/stylesNew';

export const NotFound: FC = () => {
	return (
		<MainContainer>
			<Typography variant='h3'>
				404: Page you're looking for doesn't exist
			</Typography>
		</MainContainer>
	);
};
