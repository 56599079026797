import { useMutation, useQuery } from '@apollo/client';
import { DELETE_IBC, GET_IBCS_PRACTITIONER } from 'apollo-client';
import { useEffect } from 'react';
import { useState } from 'react';
import { FC } from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Container,
  FormControl,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { Document } from 'components/views/common/Document';
import { IGetIbcsPractitioner } from 'types';
import { MouseEventHandler } from 'react';
import { TSortOrder } from 'types';
import { useAuth } from 'context/authProvider';
import { InfoSnackbar } from 'components/views/common/InfoSnackbar';
import { ErrorSnackbar } from 'components/views/common/ErrorSnackbar';
import {
  CustomAccordionSummary,
  DocumentsGridWrapper,
  PageOptions,
  SortButton,
  StatusSelect,
} from 'styles/stylesNew';
interface IData {
  getIbcsPractitioner: IGetIbcsPractitioner[];
}

export const MyIbcs: FC = () => {
  const { currentUser } = useAuth();
  const [sortOrder, setSortOrder] = useState<TSortOrder>('asc');
  const [ibcs, setIbcs] = useState<IGetIbcsPractitioner[]>([]);
  const [filterStatus, setFilterStatus] = useState<number>(1);
  const { loading, error, data, refetch } = useQuery<IData>(
    GET_IBCS_PRACTITIONER
  );
  const [deleteIbcMutation] = useMutation(DELETE_IBC);

  useEffect(() => {
    if (data) {
      const sortIbcs = [...data.getIbcsPractitioner].sort(
        (a, b) => a.ibcYear - b.ibcYear
      );
      setIbcs(sortIbcs);
    }
    refetch();
  }, [data, refetch]);

  const sortByYear: MouseEventHandler<HTMLButtonElement> = () => {
    setSortOrder((prevState) => (prevState === 'asc' ? 'desc' : 'asc'));
    const sortedIbcs = [...ibcs].sort((a, b) =>
      sortOrder === 'asc' ? b.ibcYear - a.ibcYear : a.ibcYear - b.ibcYear
    );
    setIbcs(sortedIbcs);
  };

  const deleteIbc = async (id: number) => {
    await deleteIbcMutation({
      variables: {
        deleteIbcInput: {
          id,
        },
      },
      update(cache) {
        const normalizedId = cache.identify({ id, __typename: 'Ibc' });
        cache.evict({ id: normalizedId });
        cache.gc();
      },
    });
  };

  const handleStatusFilter: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => void = (e) => {
    setFilterStatus(Number(e.target.value));
  };

  if (loading) {
    return <InfoSnackbar message={'Loading...'} open={true} />;
  }

  return (
    <Container maxWidth={false}>
      <Typography variant='h4'>My IBC's</Typography>
      <PageOptions
        container
        justifyContent='space-between'
        alignItems='flex-end'
      >
        <FormControl>
          <StatusSelect
            value={filterStatus}
            onChange={handleStatusFilter}
            id='reviewer-select'
          >
            <MenuItem value={1}>Not submitted</MenuItem>
            <MenuItem value={2}>Submitted</MenuItem>
            <MenuItem value={3}>Mid year checkpoint completed</MenuItem>
            <MenuItem value={4}>End of year checkpoint completed</MenuItem>
          </StatusSelect>
        </FormControl>
        <SortButton onClick={sortByYear}>Sort by year: {sortOrder}</SortButton>
      </PageOptions>
      {ibcs && (
        <DocumentsGridWrapper container direction='column'>
          {ibcs
            .filter((ibc) => Number(ibc.status.id) === filterStatus)
            .map((ibc) => {
              return (
                <Accordion key={ibc.id}>
                  <CustomAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls='ibc'
                    id='ibc'
                  >
                    <Typography variant='h6'>{ibc.ibcYear}</Typography>
                  </CustomAccordionSummary>
                  <AccordionDetails>
                    <Document
                      ibcId={ibc.id}
                      userId={currentUser!.id}
                      deleteIbc={deleteIbc}
                      reviewer={ibc.reviewer}
                    />
                  </AccordionDetails>
                </Accordion>
              );
            })}
        </DocumentsGridWrapper>
      )}
      {error && <ErrorSnackbar message={error.message} open={true} />}
    </Container>
  );
};
