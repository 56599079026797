import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import { FC } from 'react';
import { IUser } from 'types';
import { DateTime } from 'luxon';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { IconButtonStyle, InactiveUserRow } from 'styles/stylesNew';

interface Props {
  rows: IUser[];
  onDeleteUserClick: (id: number) => Promise<void>;
  onUpdateUserClick: (user: IUser) => void;
  showInactive: boolean;
}

export const UserTable: FC<Props> = ({
  rows,
  onDeleteUserClick,
  onUpdateUserClick,
  showInactive,
}) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow style={{ background: '#F78F1E' }}>
            <TableCell>Number</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Service Line</TableCell>
            <TableCell>Squad Lead</TableCell>
            <TableCell>Admin</TableCell>
            <TableCell>Reviewer</TableCell>
            <TableCell>Active</TableCell>
            <TableCell>Date Added</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {showInactive
            ? rows.map((user, i) => {
                if (user.activeUser) {
                  return (
                    <TableRow key={user.id}>
                      <TableCell component='th' scope='row'>
                        {i + 1}
                      </TableCell>
                      <TableCell>{user.name}</TableCell>
                      <TableCell>{user.email}</TableCell>
                      <TableCell>{user.serviceLine}</TableCell>
                      <TableCell>{user.squadLead}</TableCell>
                      <TableCell>{(user.isAdmin && 'yes') || 'no'}</TableCell>
                      <TableCell>
                        {(user.isReviewer && 'yes') || 'no'}
                      </TableCell>
                      <TableCell>
                        {(user.activeUser && 'yes') || 'no'}
                      </TableCell>
                      <TableCell>
                        {DateTime.fromISO(user.dateAdded).toLocaleString()}
                      </TableCell>
                      <TableCell>
                        <IconButtonStyle
                          onClick={() => onUpdateUserClick(user)}
                        >
                          <EditOutlinedIcon />
                        </IconButtonStyle>
                        <IconButtonStyle
                          onClick={() => onDeleteUserClick(user.id)}
                        >
                          <DeleteOutlineOutlinedIcon />
                        </IconButtonStyle>
                      </TableCell>
                    </TableRow>
                  );
                }
                return (
                  <InactiveUserRow key={user.id}>
                    <TableCell component='th' scope='row'>
                      {i + 1}
                    </TableCell>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.serviceLine}</TableCell>
                    <TableCell>{user.squadLead}</TableCell>
                    <TableCell>{(user.isAdmin && 'yes') || 'no'}</TableCell>
                    <TableCell>{(user.isReviewer && 'yes') || 'no'}</TableCell>
                    <TableCell>{(user.activeUser && 'yes') || 'no'}</TableCell>
                    <TableCell>
                      {DateTime.fromISO(user.dateAdded).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      <IconButtonStyle onClick={() => onUpdateUserClick(user)}>
                        <EditOutlinedIcon />
                      </IconButtonStyle>
                      <IconButtonStyle
                        onClick={() => onDeleteUserClick(user.id)}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </IconButtonStyle>
                    </TableCell>
                  </InactiveUserRow>
                );
              })
            : rows
                .filter((user) => user.activeUser)
                .map((user, i) => (
                  <TableRow key={user.id}>
                    <TableCell component='th' scope='row'>
                      {i + 1}
                    </TableCell>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.serviceLine}</TableCell>
                    <TableCell>{user.squadLead}</TableCell>
                    <TableCell>{(user.isAdmin && 'yes') || 'no'}</TableCell>
                    <TableCell>{(user.isReviewer && 'yes') || 'no'}</TableCell>
                    <TableCell>{(user.activeUser && 'yes') || 'no'}</TableCell>
                    <TableCell>
                      {DateTime.fromISO(user.dateAdded).toLocaleString()}
                    </TableCell>
                    <TableCell>
                      <IconButtonStyle onClick={() => onUpdateUserClick(user)}>
                        <EditOutlinedIcon />
                      </IconButtonStyle>
                      <IconButtonStyle
                        onClick={() => onDeleteUserClick(user.id)}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </IconButtonStyle>
                    </TableCell>
                  </TableRow>
                ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
