import {
  AccordionSummary,
  AppBar,
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  Grid,
  IconButton,
  Select,
  styled,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme();

const hoverColor = 'rgba(247, 143, 30, 0.8)';

export const RootContainer = styled(Container)({
  minWidth: '100%',
  marginTop: 50,
  padding: 40,
});

export const CustomAccordionSummary = styled(AccordionSummary)({
  '&:hover': {
    backgroundColor: hoverColor,
  },
});

export const GoalsFormContainer = styled(FormControl)({
  maxWidth: 700,
  boxShadow: '0px 1px 6px 1px rgba(0,0,0,0.48)',
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
});

export const LoginFormMain = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  alignItems: 'center',
});

export const FormContainer = styled(FormControl)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  padding: theme.spacing(2),
  boxShadow: '0px 3px 6px 1px rgba(0,0,0,0.48)',
});

export const InactiveUserRow = styled(TableRow)({
  backgroundColor: 'rgba(148, 148, 148, 0.7)',
});

export const MainContainer = styled(Container)({
  display: 'flex',
  justifyContent: 'center',
  overflow: 'hidden',
  maxWidth: '100%',
});

export const PageOptions = styled(Grid)({
  marginBottom: theme.spacing(2),
});

export const LoginForm = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: 500,
  padding: theme.spacing(2),
  boxShadow: '0px 3px 6px 1px rgba(0,0,0,0.48)',
  backgroundColor: '#FFF',
  borderRadius: '5px',
});

export const FormButtons = styled(Button)({
  width: 110,
  height: 40,
  backgroundColor: 'rgb(6, 80, 154)',
  color: '#FFF',
  '&:hover': {
    backgroundColor: 'rgb(247, 143, 30)',
  },
  boxShadow: '0px 2px 5px -1px rgba(0,0,0,0.65)',
  marginTop: theme.spacing(2),
});

export const UserContainer = styled(Grid)({
  marginBottom: theme.spacing(4),
});

export const DocumentsGridWrapper = styled(Grid)({
  marginBottom: theme.spacing(2),
});

export const RememberMeCheckbox = styled(Checkbox)({
  margin: 0,
  padding: 0,
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1.5),
  paddingRight: theme.spacing(1),
});

export const ButtonAuth = styled(Button)({
  width: 110,
  height: 40,
  backgroundColor: 'rgb(6, 80, 154)',
  color: '#FFF',
  '&:hover': {
    backgroundColor: 'rgb(247, 143, 30)',
  },
  boxShadow: '0px 2px 5px -1px rgba(0,0,0,0.65)',
  marginRight: theme.spacing(2),
});

export const ActionButton = styled(Button)({
  marginLeft: theme.spacing(1),
  marginBottom: theme.spacing(1),
  backgroundColor: 'rgb(214, 214, 214)',
  '&:hover': {
    backgroundColor: 'rgb(100, 100, 100)',
    color: '#FFF',
  },
});

export const HeaderText = styled(Typography)({
  marginRight: theme.spacing(2),
});

export const CustomSelect = styled(Select)({
  margin: theme.spacing(2),
  width: 130,
});
export const AppBarHead = styled(AppBar)({
  display: 'flex',
  justifyContent: 'flex-end',
  flexDirection: 'row',
  alignItems: 'center',
  zIndex: theme.zIndex.drawer - 1,
  backgroundColor: 'rgb(6, 80, 154)',
  minHeight: 50,
  padding: theme.spacing(2),
});

export const SalaryTypography = styled(Typography)({
  marginLeft: theme.spacing(2),
});

export const DocumentContentWrapper = styled(Box)({
  marginTop: theme.spacing(2),
});

export const HeaderContent = styled(Grid)({
  alignItems: 'center',
  justifyContent: 'flex-end',
  maxWidth: 600,
});

export const IconButtonStyle = styled(IconButton)({
  width: 50,
  borderRadius: '2px',
  color: '#0B2343',
  '&:hover': {
    backgroundColor: hoverColor,
  },
});

export const StatusSelect = styled(Select)({
  marginBottom: theme.spacing(1),
  width: 150,
});

export const MyField = styled(TextField)({
  padding: theme.spacing(1, 0),
});

export const MyActionButton = styled(Button)({
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
  backgroundColor: 'rgb(6, 80, 154)',
  color: '#FFF',
  '&:hover': {
    backgroundColor: 'rgb(247, 143, 30)',
  },
  boxShadow: '0px 2px 5px -1px rgba(0,0,0,0.65)',
});

export const SortButton = styled(MyActionButton)({
  width: 200,
});
