import { apolloClient } from 'apollo-client';
import { ApolloProvider } from '@apollo/client';
import { AuthProvider } from 'context/authProvider';
import { Routes } from 'Routes';
import { RootContainer } from 'styles/stylesNew';
import { ErrorBoundary } from 'components/ErrorBoundary';

function App() {
  return (
    <RootContainer>
      <ApolloProvider client={apolloClient}>
        <AuthProvider>
          <ErrorBoundary>
            <Routes />
          </ErrorBoundary>
        </AuthProvider>
      </ApolloProvider>
    </RootContainer>
  );
}

export default App;
