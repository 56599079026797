import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { FC } from 'react';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Link } from 'react-router-dom';

export const ReviewerListItems: FC = () => {
  return (
    <div>
      <Divider />
      <List>
        <ListItem button component={Link} to={'/reviewer-ibcs'}>
          <ListItemIcon>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary={"Practitioner IBC's"} />
        </ListItem>
      </List>
    </div>
  );
};
