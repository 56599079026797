import { useMutation } from '@apollo/client';
import { LOGIN } from 'apollo-client';
import { useState, useContext } from 'react';
import { createContext, FC } from 'react';
import { useHistory } from 'react-router';
import jwt_decode from 'jwt-decode';
import { useEffect } from 'react';
import { IUser, IToken } from 'types';
import { ErrorSnackbar } from 'components/views/common/ErrorSnackbar';

type TUser = Pick<IUser, 'email' | 'id' | 'isAdmin' | 'isReviewer'>;

interface IData {
  loginUser: {
    token: string;
  };
}

interface IAuthContext {
  currentUser: TUser | null;
  login: (
    email: string,
    password: string,
    rememberMeCheck: boolean
  ) => Promise<void>;
  logout: () => void;
}

const authContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: FC = ({ children }) => {
  const history = useHistory();
  const [currentUser, setCurrentUser] = useState<TUser | null>(null);
  const [loginMutation, { error }] = useMutation<IData>(LOGIN);

  const login = async (
    email: string,
    password: string,
    rememberMeCheck: boolean
  ) => {
    const result = await loginMutation({
      variables: {
        input: {
          email: email,
          password: password,
        },
      },
    });

    if (result.data) {
      const token: IToken = jwt_decode(result.data.loginUser.token);
      setCurrentUser({
        email: token.email,
        id: token.id,
        isAdmin: token.isAdmin,
        isReviewer: token.isReviewer,
      });
      if (rememberMeCheck) {
        localStorage.setItem('token', result.data.loginUser.token);
      } else {
        sessionStorage.setItem('token', result.data.loginUser.token);
      }
      history.push('/my-ibcs');
    }
  };

  useEffect(() => {
    if (localStorage.getItem('token')) {
      const token: IToken = jwt_decode(localStorage.getItem('token')!);
      setCurrentUser({
        email: token.email,
        id: token.id,
        isAdmin: token.isAdmin,
        isReviewer: token.isReviewer,
      });
    }
    if (sessionStorage.getItem('token')) {
      const token: IToken = jwt_decode(sessionStorage.getItem('token')!);
      setCurrentUser({
        email: token.email,
        id: token.id,
        isAdmin: token.isAdmin,
        isReviewer: token.isReviewer,
      });
    }
  }, []);

  const logout = () => {
    setCurrentUser(null);
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    history.push('/');
  };

  return (
    <authContext.Provider value={{ currentUser, login, logout }}>
      {children}
      {error && <ErrorSnackbar message={error.message} open={true} />}
    </authContext.Provider>
  );
};

export const useAuth = () => useContext(authContext);
