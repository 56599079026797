import { FC } from 'react';
import { GoalsFormContainer, MyActionButton, MyField } from 'styles/stylesNew';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { Typography } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { CHANGE_PASSWORD } from 'apollo-client';
import { SuccessSnackbar } from 'components/views/common/SuccessSnackbar';
import { ErrorSnackbar } from 'components/views/common/ErrorSnackbar';
import { useAuth } from 'context/authProvider';

const validationSchema = yup.object({
  oldPassword: yup.string().required('Old password is required'),
  newPassword: yup.string().min(8).required('New password is required'),
  confirmPassword: yup.string().min(8).required('Confirm password is required'),
});
export const ChangePassword: FC = () => {
  const { logout } = useAuth();
  const [updateMutation, { error, data }] = useMutation(CHANGE_PASSWORD);
  const formik = useFormik({
    initialValues: {
      oldPassword: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: async (values) => {
      await updateMutation({
        variables: {
          changePasswordInput: {
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
            confirmPassword: values.confirmPassword,
          },
        },
      });
      logout();
    },
  });
  return (
    <GoalsFormContainer>
      <form onSubmit={formik.handleSubmit}>
        <Typography variant='h5'>Change password</Typography>
        <MyField
          id='oldPassword'
          name='oldPassword'
          label='Old password'
          value={formik.values.oldPassword}
          onChange={formik.handleChange}
          type='password'
          error={
            formik.touched.oldPassword && Boolean(formik.errors.oldPassword)
          }
          helperText={formik.touched.oldPassword && formik.errors.oldPassword}
          fullWidth
        />
        <MyField
          id='newPassword'
          name='newPassword'
          label='New password'
          type='password'
          value={formik.values.newPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.newPassword && Boolean(formik.errors.newPassword)
          }
          helperText={formik.touched.newPassword && formik.errors.newPassword}
          fullWidth
        />
        <MyField
          id='confirmPassword'
          name='confirmPassword'
          label='Confirm password'
          type='password'
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
          helperText={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
          fullWidth
        />
        <MyActionButton type='submit'>Change password</MyActionButton>
      </form>
      {data && (
        <SuccessSnackbar
          message={'Password changed succesfully!'}
          open={true}
        />
      )}
      {error && <ErrorSnackbar message={error.message} open={true} />}
    </GoalsFormContainer>
  );
};
