import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { FC } from 'react';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import CreateIcon from '@material-ui/icons/Create';
import { Link } from 'react-router-dom';
import LockIcon from '@material-ui/icons/Lock';

export const PractitionerListItems: FC = () => {
  return (
    <div>
      <List>
        <ListItem button component={Link} to={'/create-ibc'}>
          <ListItemIcon>
            <CreateIcon />
          </ListItemIcon>
          <ListItemText primary={'Create IBC'} />
        </ListItem>
        <ListItem button component={Link} to={'/my-ibcs'}>
          <ListItemIcon>
            <FindInPageIcon />
          </ListItemIcon>
          <ListItemText primary={"My IBC's"} />
        </ListItem>
        <ListItem button component={Link} to={'/change-password'}>
          <ListItemIcon>
            <LockIcon />
          </ListItemIcon>
          <ListItemText primary={'Change password'} />
        </ListItem>
      </List>
    </div>
  );
};
