import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Container,
  Typography,
  Dialog,
  Box,
} from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { IGetIbcData, IGoals } from 'types';
import { DateTime } from 'luxon';
import { UpdateGoalForm } from 'components/forms/UpdateGoalForm';
import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
  useQuery,
} from '@apollo/client';
import {
  DELETE_DEVELOPMENT_GOAL,
  DELETE_PERFORMANCE_GOAL,
  GET_IBC,
} from 'apollo-client';
import { useAuth } from 'context/authProvider';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { InfoSnackbar } from './InfoSnackbar';
import { ErrorSnackbar } from './ErrorSnackbar';
import { SuccessSnackbar } from './SuccessSnackbar';
import { IconButtonStyle } from 'styles/stylesNew';

interface Props {
  goals: IGoals[];
  name: string;
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<IGetIbcData>>;
  status: number;
  userId: number;
  ibcId: number;
}

export const GoalsTableComponent: FC<Props> = ({
  goals,
  name,
  refetch,
  status,
  userId,
  ibcId,
}) => {
  const { currentUser } = useAuth();
  const [updateGoal, setUpdateGoal] = useState<boolean>(false);
  const [goal, setGoal] = useState<IGoals>();
  const [renderEditButton, setRenderEditButton] = useState<boolean>(false);
  const [deleteMutation, { loading, error, data }] = useMutation(
    name === 'Performance goal'
      ? DELETE_PERFORMANCE_GOAL
      : DELETE_DEVELOPMENT_GOAL
  );
  const { error: getIbcError, data: getIbcData } = useQuery(GET_IBC, {
    variables: {
      getIbcFilter: {
        id: ibcId,
      },
    },
  });

  useEffect(() => {
    if (getIbcData.getIbc.reviewer) {
      setRenderEditButton(
        currentUser?.isAdmin ||
          (Number(getIbcData?.getIbc.reviewer.id) === currentUser?.id &&
            status < 4 &&
            status > 1) ||
          (status === 1 && currentUser?.id === userId)
      );
    } else {
      setRenderEditButton(
        currentUser?.isAdmin || (status === 1 && currentUser?.id === userId)
      );
    }
  }, [getIbcData, currentUser?.id, currentUser?.isAdmin, status, userId]);

  const editGoal = (goal: IGoals) => {
    setGoal(goal);
    setUpdateGoal((prevState) => !prevState);
  };
  if (loading) {
    return <InfoSnackbar message={'Loading...'} open={true} />;
  }

  const deleteGoal = async (goal: IGoals) => {
    const goalInput =
      name === 'Performance goal'
        ? 'deletePerformanceGoalInput'
        : 'deleteDevelopmentGoalInput';
    await deleteMutation({
      variables: {
        [goalInput]: {
          id: goal.id,
        },
      },
    });
    refetch();
  };

  const renderDeleteButton =
    currentUser?.isAdmin || (currentUser?.id === userId && status === 1);
  return (
    <Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow style={{ background: 'rgba(247, 143, 30, 0.8)' }}>
              <TableCell width='200px'>
                <Typography variant='subtitle1'>
                  <strong>Goal description</strong>
                </Typography>
              </TableCell>
              <TableCell width='200px'>
                <Typography variant='subtitle1'>
                  <strong>Support/Resources needed</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant='subtitle1'>
                  <strong>Start date</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant='subtitle1'>
                  <strong>End date</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant='subtitle1'>
                  <strong>Mid year status</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant='subtitle1'>
                  <strong>End of year status</strong>
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant='subtitle1'>
                  <strong>Actions</strong>
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {goals.map((goal) => (
              <TableRow key={goal.id}>
                <TableCell component='th' scope='row'>
                  {goal.goalDescription}
                </TableCell>
                <TableCell>{goal.supportNeeded}</TableCell>
                <TableCell>
                  {DateTime.fromISO(goal.startDate).toLocaleString()}
                </TableCell>
                <TableCell>
                  {DateTime.fromISO(goal.endDate).toLocaleString()}
                </TableCell>
                <TableCell>{goal.midYearStatus}</TableCell>
                <TableCell>{goal.eoyYearStatus}</TableCell>
                <TableCell>
                  {renderEditButton && (
                    <IconButtonStyle onClick={() => editGoal(goal)}>
                      <EditOutlinedIcon />
                    </IconButtonStyle>
                  )}
                  {renderDeleteButton && (
                    <IconButtonStyle onClick={() => deleteGoal(goal)}>
                      <DeleteOutlineOutlinedIcon />
                    </IconButtonStyle>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Container>
        <Dialog open={updateGoal}>
          <UpdateGoalForm
            name={name}
            goalToUpdate={goal!}
            setUpdateGoal={setUpdateGoal}
            refetch={refetch}
            status={status}
          />
        </Dialog>
        {error && <ErrorSnackbar message={error.message} open={true} />}
        {data && <SuccessSnackbar message={'Goal deleted!'} open={true} />}
        {getIbcError && (
          <ErrorSnackbar message={getIbcError.message} open={true} />
        )}
      </Container>
    </Box>
  );
};
