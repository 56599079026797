import { FC } from 'react';
import {
  AppBarHead,
  ButtonAuth,
  HeaderContent,
  HeaderText,
} from 'styles/stylesNew';
import { useAuth } from '../context/authProvider';

export const Header: FC = () => {
  const { currentUser, logout } = useAuth();
  return (
    <AppBarHead position='absolute'>
      <HeaderContent container>
        <HeaderText>{currentUser?.email}</HeaderText>
        <ButtonAuth type='submit' onClick={logout}>
          Logout
        </ButtonAuth>
      </HeaderContent>
    </AppBarHead>
  );
};
