import { Snackbar } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { FC, useEffect, useState } from "react";

interface Props {
  message: string;
  open: boolean;
}

export const InfoSnackbar: FC<Props> = ({ message, open }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  useEffect(() => {
    setIsOpen(open);
  }, [open]);
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <Snackbar open={isOpen} autoHideDuration={3000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="info">
        {message}
      </Alert>
    </Snackbar>
  );
};
