import { Login } from 'components/forms/Login';
import { Main } from 'components/Main';
import { NotFound } from 'components/NotFound';
import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';

export const Routes: FC = () => {
  return (
    <Switch>
      <Route exact path='/'>
        <Login />
      </Route>
      <Route exact path='/create-ibc'>
        <Main />
      </Route>
      <Route exact path='/view-ibcs'>
        <Main />
      </Route>
      <Route exact path='/my-ibcs'>
        <Main />
      </Route>
      <Route exact path='/reviewer-users'>
        <Main />
      </Route>
      <Route exact path='/admin-ibcs'>
        <Main />
      </Route>
      <Route exact path='/change/password'>
        <Main />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
};
