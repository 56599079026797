import {
  ApolloQueryResult,
  OperationVariables,
  useMutation,
} from '@apollo/client';
import {
  Dialog,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
} from '@material-ui/core';
import { UPDATE_USER } from 'apollo-client';
import { ErrorSnackbar } from 'components/views/common/ErrorSnackbar';
import { SuccessSnackbar } from 'components/views/common/SuccessSnackbar';
import { useFormik } from 'formik';
import { ChangeEventHandler } from 'react';
import { useState } from 'react';
import { FC } from 'react';
import { FormButtons, FormContainer, MyField } from 'styles/stylesNew';
import { IUser } from 'types';
import * as yup from 'yup';

const validationSchema = yup.object({
  name: yup.string(),
  email: yup.string().email('Email must be a valid email'),
  serviceLine: yup.string(),
  position: yup.string(),
  squadLead: yup.string(),
  major: yup.string(),
});

interface IData {
  getUsers: TUser[];
}

type TUser = Omit<IUser, 'position' | 'major'>;

interface Props {
  refetch: (
    variables?: Partial<OperationVariables> | undefined
  ) => Promise<ApolloQueryResult<IData>>;
  setUser: React.Dispatch<React.SetStateAction<IUser | undefined>>;
  user: IUser;
}

export const UpdateUserForm: FC<Props> = ({ refetch, setUser, user }) => {
  const [isBools, setIsBools] = useState({
    isAdmin: user.isAdmin,
    isReviewer: user.isReviewer,
    activeUser: user.activeUser,
  });
  const [createMutation, { error, data }] = useMutation(UPDATE_USER);

  const formik = useFormik({
    initialValues: {
      name: user.name,
      email: user.email,
      serviceLine: user.serviceLine,
      position: user.position,
      squadLead: user.squadLead,
      major: user.major,
    },
    validationSchema,
    onSubmit: async (values) => {
      await createMutation({
        variables: {
          updateUserInput: {
            id: user.id,
            name: values.name,
            email: values.email,
            serviceLine: values.serviceLine,
            position: values.position,
            squadLead: values.squadLead,
            major: values.major,
            isAdmin: isBools.isAdmin,
            isReviewer: isBools.isReviewer,
            activeUser: isBools.activeUser,
          },
        },
      });

      setUser(undefined);
      refetch();
    },
  });

  const onCancelClick = () => {
    setUser(undefined);
  };

  const boolsChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setIsBools({ ...isBools, [e.target.name]: e.target.checked });
  };

  return (
    <Dialog open={true}>
      <DialogTitle id='simple-dialog-title'>Edit user</DialogTitle>
      <FormContainer>
        <form onSubmit={formik.handleSubmit}>
          <MyField
            id='name'
            name='name'
            label='Name'
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            fullWidth
          />
          <MyField
            id='email'
            name='email'
            label='Email'
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            fullWidth
          />
          <MyField
            id='serviceLine'
            name='serviceLine'
            label='Service line'
            value={formik.values.serviceLine}
            onChange={formik.handleChange}
            error={
              formik.touched.serviceLine && Boolean(formik.errors.serviceLine)
            }
            helperText={formik.touched.serviceLine && formik.errors.serviceLine}
            fullWidth
          />
          <MyField
            id='position'
            name='position'
            label='Position'
            value={formik.values.position}
            onChange={formik.handleChange}
            error={formik.touched.position && Boolean(formik.errors.position)}
            helperText={formik.touched.position && formik.errors.position}
            fullWidth
          />
          <MyField
            id='squadLead'
            name='squadLead'
            label='Squad lead'
            value={formik.values.squadLead}
            onChange={formik.handleChange}
            error={formik.touched.squadLead && Boolean(formik.errors.squadLead)}
            helperText={formik.touched.squadLead && formik.errors.squadLead}
            fullWidth
          />
          <MyField
            id='major'
            name='major'
            label='Major'
            value={formik.values.major}
            onChange={formik.handleChange}
            error={formik.touched.major && Boolean(formik.errors.major)}
            helperText={formik.touched.major && formik.errors.major}
            fullWidth
          />
          <Grid container justifyContent='space-between'>
            <FormControlLabel
              control={
                <Switch
                  checked={isBools.isAdmin}
                  onChange={boolsChange}
                  name='isAdmin'
                />
              }
              label='Admin'
            />
            <FormControlLabel
              control={
                <Switch
                  checked={isBools.isReviewer}
                  onChange={boolsChange}
                  name='isReviewer'
                />
              }
              label='Reviewer'
            />
            <FormControlLabel
              control={
                <Switch
                  checked={isBools.activeUser}
                  onChange={boolsChange}
                  name='activeUser'
                />
              }
              label='Active user'
            />
          </Grid>
          <Grid container justifyContent='space-between'>
            <FormButtons type='submit'>Update </FormButtons>
            <FormButtons onClick={onCancelClick}>Cancel</FormButtons>
          </Grid>
        </form>
      </FormContainer>
      {error && <ErrorSnackbar message={error.message} open={true} />}
      {data && (
        <SuccessSnackbar message={'Successfully updated user!'} open={true} />
      )}
    </Dialog>
  );
};
