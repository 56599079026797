import { Drawer, IconButton } from '@material-ui/core';
import { FC, useState } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import clsx from 'clsx';
import { useStyles } from 'styles/styles';
import { PractitionerListItems } from './PractitionerListItems';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
import { CreateIbc } from 'components/views/practitioner/create-ibc/CreateIbc';
import { MyIbcs } from 'components/views/practitioner/my-ibcs/MyIbcs';
import { useAuth } from 'context/authProvider';
import { AdminListItems } from 'components/side-nav/AdminListItems';
import { ReviewerListItems } from './ReviewerListItems';
import { ReviewerIbcs } from 'components/views/reviewer/ReviewerIbcs';
import { ViewUsers } from 'components/views/admin/ViewUsers';
import { ViewAllIbcs } from 'components/views/admin/ViewAllIbcs';
import { ChangePassword } from 'components/forms/ChangePassword';

export const SideNav: FC = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const { currentUser } = useAuth();

  const handleMenuToggle = () => {
    setOpen((prevState) => !prevState);
  };

  return (
    <BrowserRouter>
      <nav>
        <Drawer
          variant='permanent'
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div
            className={clsx(classes.toolbar, {
              [classes.toolbarOpen]: open,
            })}
          >
            {open ? (
              <IconButton onClick={handleMenuToggle}>
                <ChevronLeftIcon />
              </IconButton>
            ) : (
              <IconButton onClick={handleMenuToggle}>
                <MenuIcon />
              </IconButton>
            )}
          </div>
          <PractitionerListItems />
          {currentUser?.isReviewer && <ReviewerListItems />}
          {currentUser?.isAdmin && <AdminListItems />}
        </Drawer>
      </nav>
      <Switch>
        <Route exact path={'/create-ibc'} render={() => <CreateIbc />} />
      </Switch>
      <Switch>
        <Route exact path={'/my-ibcs'} render={() => <MyIbcs />} />
      </Switch>
      <Switch>
        <Route
          exact
          path={'/change-password'}
          render={() => <ChangePassword />}
        />
      </Switch>
      <Switch>
        <Route exact path={'/reviewer-ibcs'} render={() => <ReviewerIbcs />} />
      </Switch>
      <Switch>
        <Route exact path={'/view-users'} render={() => <ViewUsers />} />
      </Switch>
      <Switch>
        <Route exact path={'/admin-ibcs'} render={() => <ViewAllIbcs />} />
      </Switch>
    </BrowserRouter>
  );
};
