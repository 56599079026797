import { FC } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Typography } from '@material-ui/core';
import { useMutation } from '@apollo/client';
import { CREATE_IBC } from 'apollo-client';
import { IUser } from '../../types';
import { FormButtons, GoalsFormContainer, MyField } from 'styles/stylesNew';
import { ErrorSnackbar } from 'components/views/common/ErrorSnackbar';
import { SuccessSnackbar } from 'components/views/common/SuccessSnackbar';
import { InfoSnackbar } from 'components/views/common/InfoSnackbar';

const validationSchema = yup.object({
  name: yup.string().required('Name is required'),
  position: yup.string().required('Position is required'),
  major: yup.string().required('Major is required'),
  serviceLine: yup.string().required('Service line is required'),
  ibcYear: yup
    .number()
    .min(
      new Date().getFullYear(),
      'Year must be current or greater than current year'
    )
    .required('Number is required'),
  annualSalary: yup
    .number()
    .min(1, 'Salary must be greater than 0')
    .required('Annual salary is required'),
});

type TUser = Pick<IUser, 'id' | 'position' | 'name' | 'major' | 'serviceLine'>;

interface Props {
  user: TUser;
  setIbcId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

export const BasicInfoForm: FC<Props> = ({ user, setIbcId }) => {
  const [createIbcMutation, { loading, error, data }] = useMutation(CREATE_IBC);
  const formik = useFormik({
    initialValues: {
      name: user.name,
      position: user.position,
      major: user.major,
      serviceLine: user.serviceLine,
      ibcYear: 0,
      annualSalary: 0,
    },
    validationSchema,
    onSubmit: async (values) => {
      const result = await createIbcMutation({
        variables: {
          createIbcInput: {
            ibcYear: Number(values.ibcYear),
            annualSalary: Number(values.annualSalary),
          },
        },
      });
      setIbcId(await result.data.createIbc.id);
    },
  });

  if (loading) {
    return <InfoSnackbar message={'Loading...'} open={true} />;
  }

  return (
    <GoalsFormContainer>
      <form onSubmit={formik.handleSubmit}>
        <Typography variant='h5'>Basic Info</Typography>
        <MyField
          id='name'
          name='name'
          label='Name'
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
          fullWidth
          disabled
        />
        <MyField
          id='position'
          name='position'
          label='Position'
          type='position'
          value={formik.values.position}
          onChange={formik.handleChange}
          error={formik.touched.position && Boolean(formik.errors.position)}
          helperText={formik.touched.position && formik.errors.position}
          fullWidth
          disabled
        />
        <MyField
          id='major'
          name='major'
          label='Major'
          type='major'
          value={formik.values.major}
          onChange={formik.handleChange}
          error={formik.touched.major && Boolean(formik.errors.major)}
          helperText={formik.touched.major && formik.errors.major}
          fullWidth
          disabled
        />
        <MyField
          id='serviceLine'
          name='serviceLine'
          label='Service line'
          type='serviceLine'
          value={formik.values.serviceLine}
          onChange={formik.handleChange}
          error={
            formik.touched.serviceLine && Boolean(formik.errors.serviceLine)
          }
          helperText={formik.touched.serviceLine && formik.errors.serviceLine}
          fullWidth
          disabled
        />
        <MyField
          id='ibcYear'
          name='ibcYear'
          label='IBC year'
          type='ibcYear'
          value={formik.values.ibcYear}
          onChange={formik.handleChange}
          error={formik.touched.ibcYear && Boolean(formik.errors.ibcYear)}
          helperText={formik.touched.ibcYear && formik.errors.ibcYear}
          fullWidth
        />
        <MyField
          id='annualSalary'
          name='annualSalary'
          label='Annual salary'
          type='annualSalary'
          value={formik.values.annualSalary}
          onChange={formik.handleChange}
          error={
            formik.touched.annualSalary && Boolean(formik.errors.annualSalary)
          }
          helperText={formik.touched.annualSalary && formik.errors.annualSalary}
          fullWidth
        />
        <FormButtons type='submit'>Submit</FormButtons>
      </form>
      {error && <ErrorSnackbar message={error.message} open={true} />}
      {data && (
        <SuccessSnackbar
          message={'Document sucessfully created!'}
          open={true}
        />
      )}
    </GoalsFormContainer>
  );
};
