import {
  ApolloClient,
  InMemoryCache,
  gql,
  createHttpLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
  uri: 'https://api.ibc.iolap.academy/',
});
const authLink = setContext((_, { headers }) => {
  let token;
  if (localStorage.getItem('token')) {
    token = localStorage.getItem('token');
  } else {
    token = sessionStorage.getItem('token');
  }
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});
export const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export const LOGIN = gql`
  mutation loginUser($input: LoginUser) {
    loginUser(input: $input) {
      token
    }
  }
`;

export const DELETE_IBC = gql`
  mutation DeleteIbcMutation($deleteIbcInput: DeleteIbcInput!) {
    deleteIbc(input: $deleteIbcInput)
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation Mutation($changePasswordInput: ChangePassword!) {
    changePassword(input: $changePasswordInput) {
      id
    }
  }
`;

export const CREATE_USER = gql`
  mutation createUserMutation($createUserInput: CreateUserInput!) {
    createUser(input: $createUserInput) {
      id
      name
      email
      squadLead
      position
      serviceLine
      dateAdded
      isReviewer
      isAdmin
    }
  }
`;

export const GET_USERS = gql`
  query getUsers {
    getUsers {
      id
      name
      email
      serviceLine
      squadLead
      dateAdded
      position
      major
      isAdmin
      isReviewer
      activeUser
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUserMutation($deleteUserFilter: DeleteUserInput!) {
    deleteUser(filter: $deleteUserFilter)
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUserMutation($updateUserInput: UpdateUserInput!) {
    updateUser(input: $updateUserInput) {
      id
    }
  }
`;

export const GET_USER_AND_REVIEWERS = gql`
  query getUserAndReviewers($getUserFilter: GetUserInput!) {
    getUser(filter: $getUserFilter) {
      id
      name
      position
      major
      serviceLine
    }
    getReviewers {
      id
      name
    }
  }
`;

export const GET_FULL_USER = gql`
  query getUser($getUserFilter: GetUserInput!) {
    getUser(filter: $getUserFilter) {
      id
      name
      email
      password
      position
      major
      serviceLine
      squadLead
      dateAdded
      isAdmin
      isReviewer
      activeUser
    }
  }
`;

export const GET_USER = gql`
  query getUser($getUserFilter: GetUserInput!) {
    getUser(filter: $getUserFilter) {
      id
      name
      position
      major
      serviceLine
    }
  }
`;

export const CREATE_IBC = gql`
  mutation createIbc($createIbcInput: CreateIbcInput!) {
    createIbc(input: $createIbcInput) {
      id
    }
  }
`;

export const GET_IBCS_PRACTITIONER = gql`
  query getIbcsPractitioner {
    getIbcsPractitioner {
      id
      ibcYear
      reviewer {
        id
        name
      }
      status {
        id
        name
      }
    }
  }
`;

export const UPDATE_REVIEWER = gql`
  mutation UpdateIbcMutation($updateIbcInput: UpdateIbcInput!) {
    updateIbc(input: $updateIbcInput) {
      id
    }
  }
`;

export const GET_IBCS_REVIEWER = gql`
  query getIbcsReviewer {
    getIbcsReviewer {
      user {
        id
        name
      }
      ibcYear
      id
      status {
        id
        name
      }
    }
  }
`;

export const GET_REVIEWERS = gql`
  query getReviewers {
    getReviewers {
      id
      name
      activeUser
    }
  }
`;

export const GET_ALL_IBCS = gql`
  query getAllIbcs {
    getIbcs {
      id
      user {
        id
        name
      }
      reviewer {
        id
        name
      }
      ibcYear
      status {
        id
        name
      }
    }
  }
`;

export const UPDATE_DEVELOPMENT_GOAL = gql`
  mutation UpdateDevelopmentGoalMutation(
    $updateDevelopmentGoalInput: UpdateDevelopmentGoalInput!
  ) {
    updateDevelopmentGoal(input: $updateDevelopmentGoalInput) {
      id
      midYearStatus
    }
  }
`;

export const DELETE_CONTRIBUTION_LOG = gql`
  mutation DeleteContributionLogMutation(
    $deleteContributionLogInput: DeleteContributionLogInput!
  ) {
    deleteContributionLog(input: $deleteContributionLogInput)
  }
`;

export const UPDATE_CONTRIBUTION_LOG = gql`
  mutation UpdateContributionLogMutation(
    $updateContributionLogInput: UpdateContributionLogInput!
  ) {
    updateContributionLog(input: $updateContributionLogInput) {
      id
    }
  }
`;

export const DELETE_DEVELOPMENT_GOAL = gql`
  mutation DeleteDevelopmentGoalMutation(
    $deleteDevelopmentGoalInput: DeleteDevelopmentGoalInput!
  ) {
    deleteDevelopmentGoal(input: $deleteDevelopmentGoalInput)
  }
`;

export const DELETE_PERFORMANCE_GOAL = gql`
  mutation DeletePerformanceGoalMutation(
    $deletePerformanceGoalInput: DeletePerformanceGoalInput!
  ) {
    deletePerformanceGoal(input: $deletePerformanceGoalInput)
  }
`;

export const UPDATE_PERFORMANCE_GOAL = gql`
  mutation UpdatePerformanceGoalMutation(
    $updatePerformanceGoalInput: UpdatePerformanceGoalInput!
  ) {
    updatePerformanceGoal(input: $updatePerformanceGoalInput) {
      id
      midYearStatus
    }
  }
`;

export const CREATE_CONTRIBUTION_LOG = gql`
  mutation CreateContributionLogMutation(
    $createContributionLogInput: CreateContributionLogInput!
  ) {
    createContributionLog(input: $createContributionLogInput) {
      id
      date
      businessImpact
      accomplishment
    }
  }
`;

export const GET_IBC = gql`
  query getIbc($getIbcFilter: GetIbcInput!) {
    getIbc(filter: $getIbcFilter) {
      id
      status {
        id
        name
      }
      midReviewDate
      eoyReviewDate
      annualSalary
      reviewer {
        id
        name
      }
      performanceGoals {
        id
        startDate
        goalDescription
        endDate
        supportNeeded
        midYearStatus
        eoyYearStatus

        ibc {
          id
        }
      }
      developmentGoals {
        id
        endDate
        startDate
        goalDescription
        supportNeeded
        midYearStatus
        eoyYearStatus
        ibc {
          id
        }
      }
      contributionLogs {
        id
        date
        accomplishment
        businessImpact
        ibc {
          id
        }
      }
    }
  }
`;
export const UPDATE_IBC = gql`
  mutation UpdateIBC($updateIbcInput: UpdateIbcInput!) {
    updateIbc(input: $updateIbcInput) {
      id
    }
  }
`;
export const CREATE_PERFORMANCE_GOAL = gql`
  mutation createPerformanceGoal(
    $createPerformanceGoalInput: CreatePerformanceGoalInput!
  ) {
    createPerformanceGoal(input: $createPerformanceGoalInput) {
      id
    }
  }
`;

export const CREATE_DEVELOPMENT_GOAL = gql`
  mutation createDevelopmentGoal(
    $createDevelopmentGoalInput: CreateDevelopmentGoalInput!
  ) {
    createDevelopmentGoal(input: $createDevelopmentGoalInput) {
      id
    }
  }
`;
