import { FC } from 'react';
import { Header } from 'components/Header';
import { SideNav } from './side-nav/SideNav';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { MainContainer } from 'styles/stylesNew';

export const Main: FC = () => {
  const history = useHistory();

  useEffect(() => {
    let token;
    if (localStorage.getItem('token')) {
      token = localStorage.getItem('token');
    } else {
      token = sessionStorage.getItem('token');
    }
    if (!token) {
      history.push('/');
    }
  }, [history]);

  return (
    <MainContainer>
      <Header />
      <SideNav />
    </MainContainer>
  );
};
