import { useQuery } from '@apollo/client';
import {
  Accordion,
  AccordionDetails,
  Container,
  FormControl,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { GET_IBCS_REVIEWER } from 'apollo-client';
import { ChangeEventHandler, FC, MouseEventHandler } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Document } from 'components/views/common/Document';
import { useState } from 'react';
import { useEffect } from 'react';
import { IReviewerIbc, IUser, TSortOrder } from 'types';
import { InfoSnackbar } from '../common/InfoSnackbar';
import { ErrorSnackbar } from '../common/ErrorSnackbar';
import {
  CustomAccordionSummary,
  DocumentsGridWrapper,
  MyField,
  PageOptions,
  SortButton,
  StatusSelect,
  UserContainer,
} from 'styles/stylesNew';
interface IData {
  getIbcsReviewer: IReviewerIbc[];
}

type TUser = Pick<IUser, 'id' | 'name'>;

export const ReviewerIbcs: FC = () => {
  const [filterUsers, setFilterUsers] = useState<string>('');
  const [sortOrder, setSortOrder] = useState<TSortOrder>('asc');
  const [ibcs, setIbcs] = useState<IReviewerIbc[]>([]);
  const [practitioners, setPractitioners] = useState<TUser[]>([]);
  const [filterStatus, setFilterStatus] = useState<number>(1);
  const { error, loading, data, refetch } = useQuery<IData>(GET_IBCS_REVIEWER);
  useEffect(() => {
    if (data) {
      const practitionerArray = data.getIbcsReviewer.map((ibc) => ibc.user);
      const uniquePractitioners = [
        ...new Set(practitionerArray.map((practitioner) => practitioner)),
      ];
      setPractitioners(uniquePractitioners);
      const sortIbcs = [...data.getIbcsReviewer].sort(
        (a, b) => a.ibcYear - b.ibcYear
      );
      setIbcs(sortIbcs);
    }
    refetch();
  }, [data, refetch]);

  const handleClick: MouseEventHandler<HTMLButtonElement> = () => {
    setSortOrder((prevState) => (prevState === 'asc' ? 'desc' : 'asc'));
    const sortedIbcs = [...ibcs].sort((a, b) =>
      sortOrder === 'asc' ? b.ibcYear - a.ibcYear : a.ibcYear - b.ibcYear
    );
    setIbcs(sortedIbcs);
  };

  const handleFilterChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFilterUsers(e.target.value);
  };

  const handleStatusFilter: (
    event: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => void = (e) => {
    setFilterStatus(Number(e.target.value));
  };

  if (loading) {
    return <InfoSnackbar message={'Loading...'} open={true} />;
  }

  return (
    <Container maxWidth={false}>
      <Typography variant='h4'>Practitioner IBC</Typography>
      <PageOptions
        container
        alignItems='flex-end'
        justifyContent='space-between'
      >
        <FormControl>
          <StatusSelect
            value={filterStatus}
            onChange={handleStatusFilter}
            id='reviewer-select'
          >
            <MenuItem value={1}>Not submitted</MenuItem>
            <MenuItem value={2}>Submitted</MenuItem>
            <MenuItem value={3}>Mid year checkpoint completed</MenuItem>
            <MenuItem value={4}>End of year checkpoint completed</MenuItem>
          </StatusSelect>
        </FormControl>
        <MyField
          id='filterUsers'
          name='filterUsers'
          onChange={handleFilterChange}
          value={filterUsers}
          label='Filter users'
        />
        <SortButton onClick={handleClick}>Sort by year: {sortOrder}</SortButton>
      </PageOptions>
      {data && (
        <DocumentsGridWrapper container direction='column'>
          {practitioners
            .filter((practitioner) =>
              practitioner.name
                .toLowerCase()
                .includes(filterUsers.toLowerCase())
            )
            .map((practitioner) => {
              if (
                ibcs.filter(
                  (ibc) =>
                    ibc.user.id === practitioner.id &&
                    Number(ibc.status.id) === filterStatus
                ).length < 1
              ) {
                return null;
              }
              return (
                <UserContainer
                  container
                  key={practitioner.id}
                  direction='column'
                >
                  <Typography variant='h5'>{practitioner.name}</Typography>
                  {ibcs
                    .filter(
                      (ibc) =>
                        ibc.user.id === practitioner.id &&
                        Number(ibc.status.id) === filterStatus
                    )
                    .map((ibc) => {
                      return (
                        <Accordion key={ibc.id}>
                          <CustomAccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='ibc'
                            id='ibc'
                          >
                            <Typography variant='h6'>{ibc.ibcYear}</Typography>
                          </CustomAccordionSummary>
                          <AccordionDetails>
                            <Document ibcId={ibc.id} userId={ibc.user.id} />
                          </AccordionDetails>
                        </Accordion>
                      );
                    })}
                </UserContainer>
              );
            })}
        </DocumentsGridWrapper>
      )}
      {error && <ErrorSnackbar message={error.message} open={true} />}
    </Container>
  );
};
