import { makeStyles, createStyles } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';

const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			boxSizing: 'border-box',
			margin: 0,
			padding: 0,
		},
		appBar: {
			display: 'flex',
			justifyContent: 'flex-end',
			flexDirection: 'row',
			alignItems: 'center',
			zIndex: theme.zIndex.drawer - 1,
			backgroundColor: 'rgb(6, 80, 154)',
			minHeight: 50,
			padding: theme.spacing(2),
		},
		//table
		TableHead: {
			borderCollapse: 'collapse',
			border: '1px solid #999',
			padding: 15,
		},
		appBarSpacer: {
			marginTop: 50,
		},
		menuButton: {
			marginRight: 36,
		},
		hide: {
			display: 'none',
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
			whiteSpace: 'nowrap',
		},
		drawerOpen: {
			width: drawerWidth,
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		},
		drawerClose: {
			transition: theme.transitions.create('width', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			overflowX: 'hidden',
			width: theme.spacing(7) + 1,
			[theme.breakpoints.up('sm')]: {
				width: theme.spacing(7) + 1,
			},
		},
		toolbar: {
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'flex-end',
		},
		toolbarOpen: {
			zIndex: -9999,
		},
		content: {
			display: 'flex',
			justifyContent: 'flex-start',
			minHeight: '100vh',
			overflow: 'auto',
			minWidth: '90%',
		},
		form: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
			justifyContent: 'center',
			width: 500,
			padding: theme.spacing(2),
			boxShadow: '0px 3px 6px 1px rgba(0,0,0,0.48)',
			backgroundColor: '#FFF',
			borderRadius: '25px',
		},
		formInputs: {
			padding: theme.spacing(1),
		},
		authButtons: {
			width: 110,
			height: 40,
			backgroundColor: 'rgb(6, 80, 154)',
			color: '#FFF',
			'&:hover': {
				backgroundColor: 'rgb(247, 142, 30)',
			},
			boxShadow: '0px 2px 5px -1px rgba(0,0,0,0.65)',
			marginRight: '16px',
		},
		loginPage: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			minHeight: '100vh',
			width: '100vw',
			justifyContent: 'center',
			alignItems: 'center',
		},

		headerContent: {
			padding: 40,
		},
		basicInfoForm: {
			minWidth: 500,
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'flex-start',
			justifyContent: 'center',
			boxShadow: '0px 3px 6px 1px rgba(0,0,0,0.48)',
			padding: theme.spacing(2),
			borderRadius: '25px',
		},
		createIbcContent: {
			width: '100%',
			display: 'flex',
			flexShrink: 1,
			flexWrap: 'wrap',
			justifyContent: 'flex-start',
			gap: 20,
		},
		table: {
			display: 'flex',
			width: '100%',
		},
		viewUsers: {
			width: '100%',
			padding: 40,
		},
		width100: {
			width: '100%',
			padding: 40,
		},
		hoverSelect: {
			'&:hover': {
				backgroundColor: 'rgba(247, 143, 30, 0.8)!important' as any,
			},
		},
		divider: {
			backgroundColor: 'rgba(100, 0,0, 0.5)',
		},
		filterInput: {
			width: 400,
		},

		iconButton: {
			color: '#0B2343',
			'&:hover': {
				backgroundColor: '#F78F1E',
			},
		},
	})
);
