import { useQuery } from '@apollo/client';
import { Container, Grid, Typography } from '@material-ui/core';
import { GET_USER } from 'apollo-client';
import { BasicInfoForm } from 'components/forms/BasicInfoForm';
import { useAuth } from 'context/authProvider';
import { FC, useState } from 'react';
import { IUser } from 'types';
import { GoalForm } from 'components/forms/GoalForm';
import { InfoSnackbar } from 'components/views/common/InfoSnackbar';
import { ErrorSnackbar } from 'components/views/common/ErrorSnackbar';

interface IData {
  getUser: TUser;
}

type TUser = Pick<IUser, 'id' | 'position' | 'name' | 'major' | 'serviceLine'>;

export const CreateIbc: FC = () => {
  const { currentUser } = useAuth();
  const [ibcId, setIbcId] = useState<number>();
  const {
    loading: getUserLoading,
    error: getUserError,
    data: getUserData,
  } = useQuery<IData>(GET_USER, {
    variables: {
      getUserFilter: {
        id: currentUser?.id,
      },
    },
  });

  if (getUserLoading) {
    return <InfoSnackbar message={'Loading...'} open={true} />;
  }

  return (
    <Container maxWidth={false}>
      <Typography variant='h4'>Create IBC</Typography>
      {getUserData?.getUser && !ibcId && (
        <BasicInfoForm user={getUserData.getUser} setIbcId={setIbcId} />
      )}
      {ibcId && (
        <Grid container direction='column'>
          <GoalForm name='Performance goal' ibcId={ibcId} />
          <GoalForm name='Development goal' ibcId={ibcId} />
        </Grid>
      )}
      {getUserError && (
        <ErrorSnackbar message={getUserError.message} open={true} />
      )}
    </Container>
  );
};
